<template>
  <div class="payment-info">
      <v-data-table
        :options.sync="options"
        :headers="headers"
        :items="employeeList"
        class="table-employee"
        :class="{ 'table-stick-medical-record': employeeList.length > 15 }"
        :mobile-breakpoint="0"
        hide-default-footer
        :loading="myloadingvariable"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-row
            class="d-flex flex align-center justify-space-between px-0 py-0"
          >
            <v-col cols="12" md="8">
              <v-toolbar-title class="package-title text-capitalize pl-2"
              >{{ $t("page.profile.medical_record_employee") }}
            </v-toolbar-title>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="d-flex justify-end align-center pr-0"
            >
              <v-text-field
                class="searchField fontSet pl-3 pr-3"
                outlined
                dense
                hide-details
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search') + ' ...'"
              ></v-text-field>

              <div>
                <v-select
                  class="footerHide"
                  v-model="perPage"
                  :items="itemShowing"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{ item }">
          <tr class="hd-mr-sticky">
            <td valign="top" class="pt-2">
              <template v-if="item.approved != true">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="approveShare(item)"
                      v-bind="attrs"
                      v-on="on"
                      style="color: #00a4b3"
                    >
                      mdi-file-document-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("approve") }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <p>-</p>
              </template>
            </td>
            <td valign="top" class="pt-2">
              <template v-if="item.approved == true">
                <a href="javascript:void(0);" @click="openDialogOrder(item)">{{
                  item.order_number
                }}</a>
              </template>
              <template v-else>
                {{ item.order_number }}
              </template>
            </td>
            <td valign="top" class="pt-2">
              {{ item.name }}
            </td>
            <td valign="top" class="pt-2">{{ formatDate(item.start_date) }}</td>
            <td valign="top" class="pt-2">{{ formatDate(item.end_date) }}</td>

            <td valign="top" class="pt-2 uppercase-input">
              {{
                item.approved != true
                  ? $t("waiting_for_approval")
                  : $t("approve")
              }}
            </td>
          </tr>
        </template>

        <template v-slot:footer>
          <v-row
            class="d-flex flex align-center justify-space-between footer-payment"
          >
            <v-col cols="12" md="8">
              <p class="footer-showing">
                showing {{ itemShowStart }} to {{ itemShowStop }} of
                {{ itemsTotal }} entries
              </p>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-end">
              <custom-pagination
                @changePage="(val) => (page = val)"
                :totalPage="itemLength"
                :activePage="page"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-table>
     
    <DialogOrderDetail
      v-if="dialog_order"
      :item="dataDialogOrder"
      :tampilkan="dialog_order"
      @toggleDialog="(is_show) => toggleDialogOrder(is_show)"
    />
    </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import DialogOrderDetail from "@/components/v3/results/index.vue";
import CustomPagination from "@/components/v3/pagination.vue";

export default {
  components: { DialogOrderDetail, CustomPagination },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    myDepoHistoryListCount: 0,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    search: "",
    dialogDelete: false,
    formHasError: false,
    headers: [],
    employeeList: [],

    checkItemMonth: false,
    checkItemYear: false,
    modalStartDate: false,
    modalEndDate: false,
    start_date: "",
    end_date: "",
    start_date_filter: "",
    end_date_filter: "",
    now: "",
    dialog_order: false,
    dataDialogOrder: {
      order_id: null,
      list: [
        {
          document_information: {
            number_of_report: null,
            medical_record_number: null,
            datetime_observation: null,
            gender: null,
            plant: null,
            primary_doctor: null,
            lab_chief: null,
          },
          customers_information: {
            patient_name: null,
            address: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
            dob: null,
            patient_age: null,
          },
          product: {
            product_id: null,
            product_code: null,
            product_name: null,
          },
        },
      ],
    },
  }),
  computed: {
    dateFormatedStart() {
      return this.formatDateNow(this.start_date);
    },
    dateFormatedEnd() {
      return this.formatDateNow(this.end_date);
    },
  },
  async mounted() {
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        align: "center",
        value: "actions",
        sortable: false,
        fixed: true,
      },
      {
        text: this.$t("page.profile.order_no"),
        value: "order_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.start_date"),
        value: "name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.cart.valid_until"),
        value: "name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "approved",
        align: "center",
        sortable: true,
      },
    ];
  },
  watch: {
    page: function () {
      this.getDataDeposits();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataDeposits();
      }
    },
    search: function () {
      this.page = 1;
      this.getDataDeposits();
    },
  },
  created() {
    this.getDataDeposits();
  },
  methods: {
    async approveShare(item) {
      this.$swal({
        text: this.$t("page.profile.u_sure_want_approve_share"),
        showCancelButton: true,
        confirmButtonColor: "#1A428A",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
      }).then((result) => {
        if (result.isConfirmed) {
          var dataReady = {
            id: [item.id],
            approve: true,
          };
          API.post(
            `${process.env.VUE_APP_API_TRUST}registration/approve-sharing`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            dataReady
          ).then((res) => {
            if (res.statusCode === 200) {
              window.location.href = `/company/medical-record?tab=SHARE`;
              this.toast("success", this.$t("field.success_cancel"));
            }
          });
        }
      });
    },
    async getDataDeposits() {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}registration/get-sharing-list?query=${this.search}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        if (res.statusCode == 200) {
          const listFilter = res.results.filter(item => {
            return item.status != "EXPIRED";
          });
          this.employeeList = listFilter;
        }

        this.now = this.formatDateNow(new Date());
        this.start_date = new Date(
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
          }-${new Date().getDate()}`
        )
          .toISOString()
          .substr(0, 10);
        this.end_date = new Date().toISOString().substr(0, 10);

        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log(error);
      }
    },
    setPageMyDepoHistory(data) {
      this.myDepoHistoryListCount = data.pageStop - data.pageStart;
    },
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    downloadLab(param, type, isProduct) {
      if (isProduct) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}&type=${type}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      } else {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}&type=${type}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then((res) => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      }
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    // downloadLab(param) {
    //   API.get(
    //     `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}`,
    //     {
    //       Authorization: `Bearer ${Auth.getToken()}`,
    //     }
    //   ).then(res => {
    //     this.download = res;
    //     window.open(this.download.linkDownload, "_blank");
    //   });
    // },
    // downloadMcu(param) {
    //   API.get(
    //     `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}`,
    //     {
    //       Authorization: `Bearer ${Auth.getToken()}`,
    //     }
    //   ).then(res => {
    //     this.download = res;
    //     window.open(this.download.linkDownload, "_blank");
    //   });
    // },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatPrice(price) {
      return Intl.NumberFormat().format(price);
    },
    // sum(key) {
    //   return this.paymentList.length > 0
    //     ? this.paymentList.reduce(
    //         (a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
    //         0
    //       )
    //     : 0;
    // },
    formatDateNow(param) {
      if (!param) return null;
      return moment(param)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },

    sum(key) {
      return this.employeeList.length > 0
        ? this.employeeList.reduce(
            (a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
            0
          )
        : 0;
    },
    async openDialogOrder(item) {
      this.dataDialogOrder.order_number = item.order_number;
      this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailPopup();
    },
    async getOrderDetailPopup() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${this.dataDialogOrder.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode === 200) {
          this.dataDialogOrder.results = res.results;
          this.dialog_order = true;
        } else if (res.statusCode == 400) {
          this.toast("error", res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
@media screen and (min-width: 1023px) {
  .table-sticky-medical {
    &.theme--light.v-data-table {
      .v-data-table__wrapper {
        table {
          thead {
            z-index: 3 !important;
            tr {
              th {
                position: sticky;
                top: 0;
                z-index: 2;
                background: #ddd !important;

                &:nth-child(1) {
                  position: sticky !important;
                  position: -webkit-sticky !important;
                  left: 0;
                  z-index: 5;
                }
                &:nth-child(2) {
                  position: sticky !important;
                  position: -webkit-sticky !important;
                  left: 159px;
                  z-index: 5;
                }
              }
            }
          }
          tbody {
            tr {
              &.hd-mr-sticky:hover {
                .sticky-cmr1 {
                  background: #eee !important;
                }
                .sticky-cmr2 {
                  background: #eee !important;
                }
              }
              td {
                &:nth-child(1) {
                  position: sticky !important;
                  position: -webkit-sticky !important;
                  left: 0;
                  z-index: 2;
                  background: #fff;
                }
                &:nth-child(2) {
                  position: sticky !important;
                  position: -webkit-sticky !important;
                  left: 159px;
                  z-index: 2;
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-medical-record .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stick-medical-record .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stick-medical-record .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-medical-record .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-medical-record .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-medical-record thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}

.table-employee thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-employee thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-employee tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-employee tbody tr:hover {
  background-color: #eee !important;
  z-index: 999999;
}

.uppercase-input {
  text-transform: uppercase;
}

/* @media screen and (min-width: 1023px) {
    .sticky-cmr1{
      position: sticky!important;
      position: -webkit-sticky!important;
      left: 0;
      background: #fff;
    }
    .sticky-cmr2{
      position: sticky!important;
      position: -webkit-sticky!important;
      left: 147px;
      background: #fff;
    }
    .sticky-hmr1{
      position: sticky!important;
      position: -webkit-sticky!important;
      left: 0;
      background: #ddd;
      z-index: 9999;
    }
    .sticky-hmr2{
      position: sticky!important;
      position: -webkit-sticky!important;
      left: 147px;
      background: #ddd;
      z-index: 9999;
    }
  } */
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
// @media screen and (min-width: 1023px) {
//   .hd-mr-sticky:hover{
//     .sticky-cmr1{
//       background: #eee !important;
//     }
//     .sticky-cmr2{
//       background: #eee !important;
//     }
//     // .v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:first-child{
//     //   background: #eee !important;
//     // }
//   }
// }
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
td {
  font-size: 12px !important;
  vertical-align: top !important;
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
.package-title {
  font-size: 16px;
  font-weight: bold;
  color: #233f78;
  margin-bottom: 0;
}
</style>
